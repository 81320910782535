<template>
<div class="My-popup" @click="maskClick" v-show="IsShow">
  <div class="aqt_confirm_center   animate_active" :class="{ 'active': IsShow}">
    <div class=" aqt_confirm_buton">
      <div class="but ok" v-for="(item,index) in option" :key="index">

        <div v-if="item.name" @click="toViewFile(item)" class=" option">
          <div class="name aqt_lang_mn titlefont">
            {{item.name?item.name:''|U2M}}
          </div>
          <div class="size aqt_lang_mn titlefont">
            <span v-if="fileType==1">{{item.size|sizeFormat}}</span>
            <span v-if="fileType==2||fileType==3">{{item.voratime}}</span>
          </div>
        </div>

        <p v-else @click="toViewFile(item)" class="aqt_lang_mn titlefont option1">{{TypeName|U2M}}{{index+1}}</p>

      </div>

      <!-- <div class="but cancel" @click="onCancel" v-if="showClose">
        <p class="aqt_lang_mn titlefont">{{'ᠬᠠᠰᠤᠬᠤ'|U2M}}</p>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
export default {
  filters: {
    sizeFormat: function (limit) {
      var size = "";
      if (limit < 0.1 * 1024) { //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B"
      } else if (limit < 0.1 * 1024 * 1024) { //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB"
      } else if (limit < 1 * 1024 * 1024 * 1024) { //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB"
      } else { //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
      }

      var sizeStr = size + ""; //转成字符串
      var index = sizeStr.indexOf("."); //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
      if (dou == "00") { //判断后两位是否为00，如果是则删除00                
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size
    }
  },
  props: {
    TypeName: {
      type: String,
      default: '',
    },
    fileType: {
      type: Number,
      default: 1,
    },
    option: {
      type: Array,
      default: [{}],
    },
    option2: {
      type: String,
      default: 'ᠲᠡᠭᠡᠶ᠎ᠡ',
    },
    isAlert: {
      type: [Boolean],
      default: false,
    },
    IsShow: {
      type: [Boolean],
      default: false,
    },
    // IsInput: {
    //   type: [Boolean],
    //   default: false,
    // },
    showClose: {
      type: [Boolean],
      default: true,
    },
    message: {
      type: [String],
      default: '',
    },
    //  num: {
    //   type: [Number],
    //   default: null,
    // },
  },
  data() {
    return {
      filename: '',
      messagess: '馆藏码错误',
      InputErr: false,
      num: '',
    }
  },
  methods: {
    change(limit) {
      var size = "";
      if (limit < 0.1 * 1024) { //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B"
      } else if (limit < 0.1 * 1024 * 1024) { //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB"
      } else if (limit < 0.1 * 1024 * 1024 * 1024) { //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB"
      } else { //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
      }

      var sizeStr = size + ""; //转成字符串
      var index = sizeStr.indexOf("."); //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
      if (dou == "00") { //判断后两位是否为00，如果是则删除00                
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size
    },
    toViewFile(item) {
      this.$emit('onClick', item);
    },
    maskClick() {
      this.$emit('maskClick');
    },
    onCancel() {
      this.$emit('isClose', false);
      this.$refs.FileSelect.hide()
    },
  },
  computed: {

  },
  mounted() {
    // this.$refs.extendPopup.show() 
  },
  watch: {

  }
}
</script>

<style lang="less" scoped>
.My-popup {
  position: absolute;
  // background-color: #25262d !important;
  // opacity: .4 ;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999999;

  .active {
    bottom: 0;
    animation: gobuttom 0.4s 1;
  }

  @keyframes gobuttom {
    from {
      bottom: -30rem;
    }

    to {
      bottom: 0;
    }
  }

  .aqt_confirm_center {
    position: absolute;
    width: 100vw;
    left: 0;
    // transform: translateX(-15%);
    // top: 5rem;
    height: 60%;
    font-size: 2rem;
    border-radius: 1rem;
    // background-color: #ffffff;

    .aqt_confirm_buton {
      position: absolute;
      bottom: 0;
      // margin-left: 10px;
      // width: 16rem;
      width: 100vw;
      height: 80%;
      float: right;
      display: flex;
      justify-content: flex-start;
      overflow: scroll;
      background-color: #fff;

      .but {
        min-width: 5rem !important;
        // height: 90%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          // margin: auto;

        }

        .left {
          text-align: left;
        }

        .option {
          padding: 10px 0;
          font-size: 14px;
          color: #333;
          height: calc(100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }

        .option1 {
          padding: 10px 0;
          font-size: 14px;
          color: #333;
          height: calc(100%);
        }
      }

      .but:nth-child(1) {
        border-radius: 1rem 0 0 1rem;
      }

      .ok {
        margin: 10px 0 5px 0;
        // background-color: var(--themeColor);
        border-right: 1px solid #ccc;
      }

      .cancel {
        background-color: #fff;
        color: #000000;
        border-radius: .5rem;
        margin-left: 5px;
      }

    }
  }
}
</style>
